<template>
  <div class="height-100">
    <v-main class="height-100">
      <v-container class="height-100  pt-0" fluid >
         <v-row class="pl-lg-4 ml-md-4">
          <v-col cols="12">
            <v-toolbar flat>
              <v-icon size="90">$vuetify.icons.osg-logo</v-icon>
              <v-divider class="mx-6 grey lighten-1" vertical></v-divider>
              <span class="primary--text text-subtitle-1">{{ $t('login.appTitle') }}</span>
            </v-toolbar>
          </v-col>
        </v-row>
        <v-row  v-if="!isTokenInvalid" class="height-75" align="center" justify="center">
          <v-col cols="12">
            <v-row align="center" justify="center">
              <v-col class="d-flex justify-center py-0">
                <v-alert v-model="alert" border="left" max-width="560" elevation="5" colored-border dense type="error">
                  <span v-if="message">{{ message }}</span>
                  <ul v-else> <li v-for="(item, index) in messageArray" :key="index">{{item.message}}</li></ul>
                </v-alert>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="8" md="4" lg="4" xl="3">
                <v-row no-gutters>
                  <v-col cols="12">
                    <label class="text-h3 secondary--text">{{ $t('changePassword.title') }}</label>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-form ref="changePasswordForm"
                      v-model="valid"
                      lazy-validation>
                      <v-row >
                        <v-col cols="12">
                          <v-text-field
                            v-model="password"
                            id="password"
                            :label="$t('changePassword.password.inputLabel')"
                            :rules="[rules.min, rules.upperCase, rules.lowerCase, rules.number]"
                            name="password"
                            class="password rounded"
                            autocomplete="off"
                            hide-details="auto"
                            dense
                            outlined
                            error-count="4"
                            :append-icon="isPasswordVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                            :type="isPasswordVisible ? 'text' : 'password'"
                            @click:append="isPasswordVisible = !isPasswordVisible"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row >
                        <v-col cols="12">
                          <v-text-field
                            v-model="confirmPassword"
                            id="confirmPassword"
                            :label="$t('changePassword.password.confirmInputLabel')"
                            :rules="[rules.passwordConfirmation(this.password, this.confirmPassword)]"
                            name="confirmPassword"
                            dense
                            outlined
                            hide-details="auto"
                            autocomplete="off"
                            class="rounded"
                            required
                            :append-icon="isConfirmPasswordVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                            :type="isConfirmPasswordVisible ? 'text' : 'password'"
                            @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <v-btn
                      depressed
                      color="primary"
                      min-width="178px"
                      class="text-osg-button rounded"
                      @click="validate()"
                    >{{ $t('changePassword.changePasswordButton') }}</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-else class="height-75" align="center" justify="center">
          <v-col cols="12"
            class="py-0">
            <MessageTemplate icon-name="osg-error"
              :title="$t('changePassword.invalidToken.title')">
              <v-container slot="actions"> 
                <v-row>
                  <v-col cols="10" sm="4" md="3" lg="3" xl="3" 
                    class="mx-auto text-center border-top pt-3" 
                    >
                    <v-btn
                      depressed
                      min-width="154px"
                      max-width="154px"
                      rounded
                      color="primary"
                      class="text-button mt-3 rounded"
                      @click="returnToLogin()">{{ $t('changePassword.invalidToken.button') }}</v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-6">
                  <v-col cols="10" sm="3" md="3" lg="3" xl="3" 
                    class="mx-auto text-center pt-3" 
                    >
                    <v-row>
                      <v-col cols="12" class="pa-0">
                        <label class="justify-center text-caption text-center">{{ $t('changePassword.invalidToken.label') }}</label>
                      </v-col>
                      <v-col cols="12" class="pa-0">
                        <v-btn
                          text small
                          min-width="154px"
                          color="primary"
                          rounded
                          class="text-button"
                          @click="forgotPassword()">{{ $t('changePassword.changePasswordButton') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </MessageTemplate>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <OsiguFooter />  
  </div>
</template>

<script>
import { inputRules } from '@/utils'
import OsiguFooter from '../footer/OsiguFooter.vue';
import MessageTemplate from '@/components/message/MessageTemplate';
import authService from '@/services/auth.service'
import { createNamespacedHelpers } from 'vuex'
const authModule = createNamespacedHelpers('auth');

export default {
  props: {
    source: String,
    token: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      valid: true,
      isTokenInvalid: false,
      password: '',
      confirmPassword: '',
      alert: false,
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
      message: '',
      messageArray: [],
      rules: inputRules
    }
  },
  components: {
    OsiguFooter,
    MessageTemplate
  },
  mounted() {
    this.validateToken();
  },
  methods: {
    ...authModule.mapActions(['requestChangePassword']),
    returnToLogin () {
      this.$router.push('/login')
    },
    forgotPassword() {
      this.$router.push('/forgot-password')
    },
    validateToken() {
      this.alert = false
      authService.validateToken(this.token)
      .catch((error) => {
        this.message = this.$i18n.t('globalErrorUnknown')
        const response = error.response
        if (response) {
          const status = response.status
          if (status === 409) {
            this.message = response.data.message
            this.messageArray = []
          }

          if (status === 400) {
            this.isTokenInvalid = true;

            return;
          }
        }
        this.alert = true
      }); 
    },
    changePassword() {
      this.alert = false
      this.requestChangePassword({ password: this.password, token: this.token })
        .then(() => {
          this.$router.push('/login')
        })
        .catch((error) => {
          this.message = this.$i18n.t('globalErrorUnknown')
          const response = error.response
          if (response) {
            const status = response.status
            if (status === 409) {
              this.message = response.data.message
              this.messageArray = []
            }

            if (status === 422) {
              const exceptionData = response.data
              if (exceptionData.errors) {
                if (Array.isArray(exceptionData.errors)) {
                  this.messageArray = exceptionData.errors
                  this.message = ''
                }
              }
            }
          }
          this.alert = true
        })
    },
    validate: function () {
      if (!this.token) {
        this.message = this.$i18n.t('globalErrorUnknown')
        this.alert = true
        return
      }

      if (this.$refs.changePasswordForm.validate()) {
        this.changePassword()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.height-100 {
  height: 100%;
}

.height-75 {
  height: 75%;
}

.border-top {
  border-top-style: solid;
  border-top-width: 1px;
  border-color: #e0e0e0;
}

::v-deep .password.v-input {
  .v-text-field__details {
    padding-bottom: 2px;
  }
}
</style>